/* eslint-disable react-hooks/exhaustive-deps */
import { Steps, Button, message, Alert } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddSeller } from "./component/AddSeller";
import { CreateERTS } from "./component/CreateERTS";
import ListingForm from "./component/ListingForm";
import { buildPropertyFullAddress, changeExtensiontoLowerCase, combineBrokersFirmName } from "../Common/utils/extraFunctions";
import { clientAction } from "../Client/state/actions";
import { Loading } from "../Common/components/Loading";
import { useMediaQuery } from "react-responsive";
import { useLocation, useHistory } from "react-router-dom";
import { InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/lib/upload/Dragger";
import { ERTSAction } from "./state/action";
import moment from "moment";
import { defaultERTSData } from "../Common/commondata/commonData";
import { documentAction } from "../DocumentSection/state/actions";
import Step2 from "../DocumentSection/components/OfferClone/StepTwo";
import { getFullNamesFromArray, prepareAgent } from "../DocumentSection/utils/helper";

const { Step } = Steps;

const ErtsModel = ({ importModalStep, setisModalOpen, isModalOpen, listingModalFields, setListingModalFields, handleClear, delegate, docsModal, handleCancelErts ,addCorps,addPersons,setAddPerson,setAddCorps}) => {
 
  const { getLicenseData } = useSelector((state) => state.account);
  const [brokerLicenseId, setBrokerLicenseId] = useState("");
  const [confirmModal, setconfirmModal] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const spot1 = useMediaQuery({ maxWidth: 573 });
  const location = useLocation();
  const history = useHistory();
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const { getUserDetailsData } = useSelector((state) => state.account);
  const { clientsArray} = useSelector((state) => state.documentSection.CorpReducer);
  const { pdfImportLoading } = useSelector((state) => state.documentSection.urlImportReducer);
  const {  urlImportErrorAccured, CurrentPdfImportData } = useSelector((state) => state.documentSection.urlImportReducer);
  const [ertsType, setErtsType] = useState({
    // For Sake of alter the choice in case of Mark Eibner Brokerage
    contractType: 'ERTS',
    contractId: '66aa0f8695a14f0a36fc6e12'
  })
  const [documentSetId, setDocumentSetId] = useState(null);

  const prev = () => {
    setisModalOpen({...isModalOpen,importModalStep:importModalStep - 1})
    setAddCorps(false)
    setAddPerson(false)
  };

  const next = () => {
    setisModalOpen({...isModalOpen,importModalStep:importModalStep + 1})

  };
  useEffect(() => {
    if (getLicenseData?.length > 0) {
      for (let i = 0; i < getLicenseData?.length; i++) {
        if (getLicenseData[i].idType === "COLORADO STATE LICENSE") {
          setBrokerLicenseId(getLicenseData[i]?.id?.toUpperCase());
        }
      }
    }
  }, [getLicenseData]);

  const delegateSeller = listingModalFields?.delegateSeller;

  const handleBuildDocument = () => {
    let { StreetName = "", StreetNumber = "", City = "", State = "", DirPrefix = "", DirSuffix = "", ZipCode = "", UnitLabel = "", UnitNumber = "", priceType = "", startPeriod, endPeriod, StreetSuffix = "" } = listingModalFields || {};

    const { officeDetails, agents: SellerAgents } = prepareAgent({ delegate, delegateAgent: delegateSeller, getUserDetailsData, brokerLicenseId });

    const fullAddress = buildPropertyFullAddress(listingModalFields);

    const buildData = {
      openForPropertyDoc: "SELLER_AGENT",
      ...(delegate && { delegateOwnerId: delegateSeller?.id }),
      contractType: ertsType.contractType || "ERTS",
      token: localStorage.getItem("userAuth"),
      RTDInitiator: "SELLER",
      openFor: "SELLER_AGENT",
      contractId: ertsType?.contractId || "66aa0f8695a14f0a36fc6e12", // New ERTS 2024
      builtForSection: "DOCUMENT",
      builtForId: delegate ? delegateSeller?.id : getUserDetailsData?._id,
      key: delegate ? delegateSeller?.id : getUserDetailsData?._id,
      signable: true,
      ...(documentSetId ? {documentSetId: documentSetId} : {}),
      urlData: {
        StreetName,
        StreetNumber,
        DirPrefix,
        DirSuffix,
        StreetSuffix,
        UnitNumber,
        UnitLabel,
        City,
        State,
        ZipCode: Number(ZipCode) || "",
        FullAddress: fullAddress,
        Sellers: clientsArray || [],
        PurchasePrice: priceType,
        ListingPeriodBeginDate: startPeriod,
        ListingPeriodEndByDate: endPeriod,
        SellerAgents,
        SellerBrokersList: getFullNamesFromArray(SellerAgents),
        SellerBrokersFirmList: combineBrokersFirmName(SellerAgents),
        officeDetails,
      },
    };
    dispatch(clientAction.draftDocumentUlShortning(buildData, location?.pathname));
  };
  

  const [file, setFile] = useState({});
  const [fileNametext, setFileNametext] = useState({});
  const uploadFile = useRef("");

  function beforeUpload(file) {
    let supportedFormats = file?.type;

    if (supportedFormats?.includes("pdf")) {
      const isPdf = file.type === "application/pdf";
      if (!isPdf) {
        message.error("You can only upload PDF file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        message.error("File must be smaller than 100MB!");
      }
      return isPdf && isLt2M;
    }
  }

  const redirectFunc = (propertyId) => {
    history.push({
      pathname,
      state: {
        category: "SELLER",
        categoryId: (propertyId)
        // categoryId: ('6660203e8b3d668bf3d68747')
      }
    })
  }

  const uploadTemp = (createErts) => {
    if (createErts) {
      const transactionData = {
        ...CurrentPdfImportData,
        contractId: ertsType?.contractId || "66aa0f8695a14f0a36fc6e12", // new ERTS 2024
        contractType: ertsType.contractType || "ERTS",
        Sellers: clientsArray || [],
        UnitLabel: CurrentPdfImportData?.UnitLabel?.trim(),
        UnitNumber: CurrentPdfImportData?.UnitNumber,
        // ...(delegate ? { delegateOwnerId:  delegateSeller?.id  } : {}),
      };

      dispatch(ERTSAction.uploadERTSFile({ file, createErts: true, ...(delegate ? { delegateOwnerId: delegateSeller?.id } : {}), ...(documentSetId ? {documentSetId: documentSetId} : {}), transactionData, handleCancelErts, delegate, redirectFunc }));
      dispatch(documentAction.setCorpState({ clientsArray: [] }));
      setFileNametext({});
      setFile({});
      setListingModalFields({
        ...defaultERTSData({ moment }),
      });
    } else {
      const data = {
        file,
        docsModal,
        createErts: false,
        importModalStep,
        ...(delegate ? { delegateOwnerId: delegateSeller?.id } : {}),
        ...(documentSetId ? {documentSetId: documentSetId} : {}),
        next,
        setconfirmModal,
      };
      dispatch(ERTSAction.uploadERTSFile(data));
    }
  };

  function bytesToSize(bytes) {
    let sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  let handleCustome = (data) => {
    setFileNametext({name:changeExtensiontoLowerCase(data?.file?.name), size:data?.file?.size});
    const formData = new FormData();
    formData.append("file", data?.file);
    const fileName = changeExtensiontoLowerCase(data?.file?.name)
    if (fileName?.includes(".pdf")) {
      formData.append("fileType", "PDF");
    }
    uploadFile.current = formData;
    const reader = new FileReader();
    reader.readAsDataURL(data.file);
    reader.onload = () => {};
    reader.onerror = (error) => {
      return error;
    };
    setFile(data.file);
  };



  const steps = [
    {
        title: "First",
        content:<AddSeller listingModalFields={listingModalFields} setListingModalFields={setListingModalFields}  delegate={delegate} addPersons={addPersons} addCorps={addCorps} setAddPerson={setAddPerson} setAddCorps={setAddCorps} step={"First"}  />,
      },
      {
        title: "Second",
        content: (
          <CreateERTS
            setisModalOpen={setisModalOpen}
            next={next}
            importModalStep={importModalStep}
            setErtsType={setErtsType}
            setDocumentSetId={setDocumentSetId}
            listingModalFields={listingModalFields}
            delegate={delegate}
          />
        ),
      },
      {
          title: "Third",
          content: <ListingForm listingModalFields={listingModalFields} setListingModalFields={setListingModalFields}  />,
      }
  ];


  const uploadSteps =[
    {
    title: "Upload",
    content:<div id="uploaddiv" style={
        ( isMobile && docsModal && importModalStep ===0) ? { marginTop:"", height:"220px" ,} :
        (delegate && isMobile && docsModal && importModalStep ===0) ? { marginTop:"", height:"220px" ,} :
        (delegate && spot1 && docsModal && importModalStep ===0) ? { width:"100%", height:"220px" } :
        (docsModal && importModalStep ===0) ? { width:"100%", height:"220px" } :
        { width: "100%", margin: "auto", display:"flex",justifyContent:"center",flexDirection:"column", }}>
        <div>
            <Dragger
            accept=".pdf"
            progress={false}
            showUploadList={false}
            customRequest={handleCustome}
            beforeUpload={beforeUpload}
            >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Paste, Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
                Support for a single document/pdf upload.
            </p>
            </Dragger>
        </div>
        <div style={{textAlign:"center",marginTop:"20px"}}>
            {fileNametext?.name && <h3>{fileNametext?.name}</h3>}
            {fileNametext?.size && <h6>Size:{" "}{bytesToSize(fileNametext?.size)}</h6>}
        </div>
        </div>,
  },
  {
      title: "Confirm",
      content: <Step2 source={"ERTS_IMPORT"} confirmModal={confirmModal} setconfirmModal={setconfirmModal} handleCancelErts={handleCancelErts} />,
  }];

 let modalSteps = docsModal ? uploadSteps : steps;

 useEffect(() => {
    if (urlImportErrorAccured) {
        handleCancelErts();
      dispatch(documentAction.urlImportErrorClear());
    }
  }, [urlImportErrorAccured]);

 useEffect(() => {
    let checkDocType = true;
    let PasteEventFunction = (event) => {
      let count = 0;
      let error = null;
      var items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let index in items) {
        var item = items[index];
        if (item.kind === "file" && count === 0) {
          var blob = item.getAsFile();
          try {
            let d = beforeUpload(blob);
            checkDocType = d;
            if (!d) {
              message.error("You can only upload PDF file!");
            }
          } catch (err) {
            error = err.message;
            break;
          }
          var reader = new FileReader();
          const ide = Math.floor(10000 + Math.random() * 90000);
          const imageName = "pastedImage" + ide;
          var updatedBlob = new File([blob], imageName, { type: blob.type });

          reader.onload = function (event) {
            // Handle image loading
          };
          reader.readAsDataURL(blob);
          count = +count + 1;
        }
      }
      if (error) {
        message.error(error);
      } else if (updatedBlob) {
        if (checkDocType) {
          setFileNametext({name:changeExtensiontoLowerCase(blob?.name), size: blob?.size});
          let fileSize = bytesToSize(blob?.size);
          const formData = new FormData();
          formData.append("file", updatedBlob);
          formData.append("fileType", "PDF");
          // formData.append('filename', fileNametext);
          uploadFile.current = formData;
        }
        setFile(updatedBlob);
      }
    };

    let getModal = document.querySelector("#uploaddiv");

    getModal?.addEventListener("paste", PasteEventFunction);
    return () => {
      getModal?.removeEventListener("paste", PasteEventFunction);
    };
  });


  return pdfImportLoading ? (
    <div style={{ margin: "10% 0% 10% 0%", height: isMobile ? "300px" : "400px", display:"flex", justifyContent:"center",}}>
      <Loading />
    </div>
  ) : (
    <div style={{width:"100%",}}>
        {(docsModal && importModalStep === 1 ) &&
        (<div id="showMsgDiv" style={{ fontSize: "10px", fontWeight: "400", textAlign: "left",margin:"-15px 0px 10px 0px" }}>
                <Alert style={{ width: "100%" }} message={"Please verify the information below matches the values in the pdf. We've done our best to retrieve values from the pdf, but occasionally the values are not formatted correctly. Changing the information below should only be done to make it consistent with the values in the imported listing. You may need to complete a amend/extend document to change any values in the imported listing."} type="info" showIcon />
        </div>)}

      <Steps current={importModalStep}>
        {modalSteps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content" style={{ marginTop: isMobile?"20px":"10px", width:"100%", height: isMobile ? "300px" : importModalStep !== 2 ? "auto" : "300px"}}>
        <div style={
            (importModalStep ===1 && !docsModal) || (docsModal && importModalStep === 0) ? { minHeight:"480px", display:"flex", justifyContent:"center"}:
            { minHeight:"480px"}}>
           {docsModal ? modalSteps[importModalStep]?.content : steps[importModalStep]?.content}
        </div>
      </div>

      <div className="steps-action"
       style={
        (delegate && isMobile && docsModal && importModalStep ===1) ? { marginTop: "10px"} :
        (delegate && isMobile && docsModal && importModalStep ===0) ? { marginTop: "10px"} :
        (delegate && spot1 && docsModal  && importModalStep ===0) ? { marginTop: "-140px"} :
        (delegate && docsModal && importModalStep===0) ? { marginTop: "-70px" } :
        (delegate && isMobile && importModalStep === 1) ? { marginTop: "-120px"} :
        (delegate && isMobile && importModalStep === 0) ? { marginTop: "260px"} :
        (delegate && isMobile) ? { marginTop: "10px"} :
        (delegate && spot1 && importModalStep ===0) ? { marginTop: "160px"} :
        (delegate && importModalStep===1) ? { marginTop: "10px" } :
        (delegate && importModalStep===2) ? { marginTop: "200px" } :
        delegate ? {marginTop:"80px"}:
                //   delegate ends here
                  (isMobile && docsModal && importModalStep ===1) ? { marginTop: "10px"} :
                  (isMobile && docsModal && importModalStep ===0) ? { marginTop: "10px"} :
                  (spot1 && docsModal  && importModalStep ===0) ? { marginTop: "-140px"} :
                  (isMobile && importModalStep === 2) ? { marginTop: "10px"} :
                  (isMobile && importModalStep === 1) ? { marginTop: "-120px"} :
                  (isMobile && importModalStep === 0)  ? {marginTop:"10px"} :
                  ( spot1 && importModalStep ===0) ? { marginTop: "80px"} :
                  (docsModal && importModalStep===0) ? { marginTop: "-100px" } :
                  (docsModal && importModalStep===1) ? { marginTop: "10px" } :
                  (importModalStep === 2) ? { marginTop:"200px"}
                  : { marginTop: "10px",}

            
      }>
        {importModalStep > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        {importModalStep === 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => handleCancelErts()}
          >
            Cancel
          </Button>
        )}

        {importModalStep < ( docsModal ? uploadSteps?.length - 1 : steps?.length - 1) && (
          <Button type="primary" onClick={() => {docsModal ? uploadTemp(false) : next()}}
          disabled={
              (docsModal && importModalStep === 0) ?
              (Object.keys(file).length ===0) :
              importModalStep === 0
              ? (
                  clientsArray?.length === 0 ||
                  (delegate && !delegateSeller?.id)
                )
              : (
                  !listingModalFields.startPeriod ||
                  !listingModalFields.endPeriod ||
                  !listingModalFields.StreetName ||
                  !listingModalFields.StreetNumber ||
                  !listingModalFields.City ||
                  !listingModalFields.State ||
                  !listingModalFields.ZipCode
                )
          }
          >
            Next
          </Button>
        )}
       {(docsModal ? importModalStep === 1 : importModalStep === 2 )  &&
       <Button
       type="primary"
       disabled={
        (docsModal && importModalStep === 1 ) ?
        (
            !CurrentPdfImportData.ListingPeriodBeginDate ||
            !CurrentPdfImportData.ListingPeriodEndByDate ||
            !CurrentPdfImportData.StreetName ||
            !CurrentPdfImportData.StreetNumber ||
            !CurrentPdfImportData.City ||
            !CurrentPdfImportData.State ||
            !CurrentPdfImportData.ZipCode
          ):
        (
            !listingModalFields.startPeriod ||
            !listingModalFields.endPeriod ||
            !listingModalFields.StreetName ||
            !listingModalFields.StreetNumber ||
            !listingModalFields.City ||
            !listingModalFields.State ||
            !listingModalFields.ZipCode
          )
       }
       onClick={() => {docsModal ? uploadTemp(true) : handleBuildDocument()}}>
        <span>Save And {docsModal ? "Import" : "Create"}</span>
        </Button>}
      </div>
    </div>
  );
};

export { ErtsModel };
