/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  DatePicker,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Dropdown,
  Menu,
  message,
} from "antd";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import {
  EditTwoTone,
  MoreOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { Loading } from "../../Common/components/Loading";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { EmptyData } from "../../Common/components/EmptyData";
import { convertDateStringToTimeStamp } from "../../Landing/utils/utilFunctions";
import { useMediaQuery } from "react-responsive";
import { PropertyHandlers } from "../state/actions";
import axios from "axios";
import styles from "../styles/tasks.module.css";
import { checkIfDisabled, disabledTransactionText } from "../../Common/commondata/commonData";
import EditableTableCell from "./EditableTableCell";

function Tasks({ state, setState, agentType, section, address, offerId, token }) {
  const delegate = (section === "DELEGATE");
  const isClientDashboard = (section === 'CLIENT_SCREEN');
  const isMediumScreen = useMediaQuery({maxWidth: 750, minWidth: 501});
  const isSmallScreen = useMediaQuery({maxWidth: 500});
  const [childrenState, setChildrenState] = useState({
    tasksCount: 20,
    isEdit: false,
    editDueDate: false,
    clickedItem: {},
    changedDate: null,
    sortToggle: {
      sort: "itemNo",
      order: "ASC",
    },
  });
  const {
    tasksCount,
    isEdit,
    editDueDate,
    clickedItem,
    changedDate,
    sortToggle,
  } = childrenState;

  const [form] = Form.useForm();
  const { Option } = Select;
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const dispatch = useDispatch();
  const { getIndividualTransactionData } = useSelector(
    (state) => state.rtdTransactionLane,
    shallowEqual,
  );

  const { tasksData, getTasksDataLoading, updatedPropertTask } = useSelector(
    (state) => state.PropertyReducer,
  );

  const delegateSide =  delegate ? ((agentType === "SELLER") ?  "SELLER" : "BUYER"): "";
  const delegateOwnerId =  delegate ? getIndividualTransactionData?.currentAgentId?._id : "";
  const formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      xs: {
        span: 24,
      },
      sm: {
        span: 16,
      },
    },
  };

  let unmounted = {
    value: false,
  };
  let source = axios.CancelToken.source();
  useEffect(() => {
    dispatch(PropertyHandlers.getTasks({offerId: (isClientDashboard && offerId) ? offerId : getIndividualTransactionData?.offerId, agentType, unmounted, source, delegateSide, delegateOwnerId, token, isClientDashboard}));

    return () => {
      unmounted.value = true;
      source.cancel("Cancelling in cleanup");
    };
  }, [updatedPropertTask]);


  // when clicks on the Edit icon
  const handleEdit = (item) => {
    setChildrenState((prevState) => ({
      ...prevState,
      clickedItem: item,
      isEdit: true,
    }));
  };

  const handleEditDueDate = (item) => {
    setChildrenState((prevState) => ({
      ...prevState,
      clickedItem: item,
      editDueDate: true,
    }));
  };
  // when we click on the save button
  const handleDateChangeSave = (el) => {
    setChildrenState((prevState) => ({
      ...prevState,
      isEdit: false,
      clickedItem: {},
    }));
    if (changedDate) {
      el.completedDateMili = convertDateStringToTimeStamp(changedDate);
      dispatch(
        PropertyHandlers.updateTasks({data: el, offerId: getIndividualTransactionData?.offerId, agentType, source: "UPDATE_COMPLETE", delegateSide, delegateOwnerId}),
      );
    } else {
      dispatch(
        PropertyHandlers.updateTasks({data: el, offerId: getIndividualTransactionData?.offerId, agentType, source: "MARK_UNCOMPLETE", delegateSide, delegateOwnerId}),
      );
    }
  };

  const handleDueDateChangeSave = (el) => {
    setChildrenState((prevState) => ({
      ...prevState,
      editDueDate: false,
      clickedItem: {},
    }));
    if (changedDate) {
      let urlData = {
        ...(el.type === "TEXT" ? { startTimestamp: changedDate } : { dateOrDeadline: changedDate }),
      };
      dispatch(PropertyHandlers.editCustomTask({ taskId: el?._id, urlData, offerId: getIndividualTransactionData?.offerId, agentType, delegateSide, delegateOwnerId }));
    }
  };
  // when clicks on the checkboxex to mark as complete or incomplete
  const handleComplete = (el, tag) => {
    dispatch(PropertyHandlers.updateTasks({data: el, offerId: getIndividualTransactionData?.offerId, agentType, source: tag, delegateSide, delegateOwnerId}));
  };
  let handleLoadMore = () => {
    setChildrenState((prevState) => ({
      ...prevState,
      tasksCount: tasksCount + 6,
    }));
  };

  const handleStatusChange = (value) => {
    value === "COMPLETED"
      ? setState((prevState) => ({ ...prevState, status: true }))
      : setState((prevState) => ({ ...prevState, status: false }));
  };

  const handleCustomModalCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editToggle: false,
      editClickedData: {},
      customModalVisible: false,
      taskId: "",
    }));
  };

  const handleCustomTask = (values) => {
    let urlData = {
      ...(values?.title?.length > 0 ? { eventTitle: values?.title } : {}),
      ...(values?.dueDate
        ? { dateOrDeadline: moment(values?.dueDate).valueOf() }
        : {}),
      ...(values?.description?.length > 0
        ? { description: values?.description }
        : {}),
      ...(values?.status?.length > 0 ? { status: values?.status } : {}),
      ...(values?.completedDate && values?.status === "COMPLETED"
        ? { completedDateMili: moment(values?.completedDate).valueOf() }
        : values?.status === "COMPLETED"
          ? { completedDateMili: moment().valueOf() }
          : {}),
      dashboardType: "CONTRACT_REMINDER",
      offerId: getIndividualTransactionData?.offerId,
      agentType,
      propertyId: getIndividualTransactionData?.propertyId,
      ...(delegateSide ? {delegateSide, delegateOwnerId} : {})
    };
    dispatch(PropertyHandlers.addCustomTask(urlData));
    setState((prevState) => ({
      ...prevState,
      editClickedData: {},
      customModalVisible: false,
      status: false,
    }));
    form.resetFields();
  };

  const MenuItem = ({ item }) => {
    return (
      <Menu size="large">
        <Menu.Item key="1" onClick={() => handleEditModalOpen(item?._id)}>
          <span style={{ color: "#04396B" }}>Edit</span>
        </Menu.Item>
        <Menu.Item key="2">
          <span
            style={{ color: "#04396B" }}
            onClick={() => handleDelete(item?._id)}
          >
            Delete
          </span>
        </Menu.Item>
      </Menu>
    );
  };

  const handleEditModalOpen = (id) => {
    setState((prevState) => ({
      ...prevState,
      editClickedData: {},
      editToggle: true,
    }));
    let data = tasksData?.filter((el) => el._id === id);
    setState((prevState) => ({
      ...prevState,
      editClickedData: data?.[0],
      customModalVisible: true,
      taskId: id,
    }));
    if (data[0]?.completedDateMili) {
      setState((prevState) => ({
        ...prevState,
        status: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        status: false,
      }));
    }
  };

  const handleEditModal = (values) => {
    let urlData = {
      ...(values?.title?.length > 0 ? { eventTitle: values?.title } : {}),
      ...(values?.dueDate
        ? { dateOrDeadline: moment(values?.dueDate).valueOf() }
        : {}),
      // ...(values?.description?.length > 0
      //   ? { description: values?.description }
      //   : {}),
      description:values?.description,
      ...(values?.status?.length > 0 ? { status: values?.status } : {}),
      ...(values?.completedDate && values?.status === "COMPLETED"
        ? { completedDateMili: moment(values?.completedDate).valueOf() }
        : {}),
    };
    if (urlData && Object.keys(urlData)?.length > 0) {
      dispatch(
        PropertyHandlers.editCustomTask({taskId: state?.taskId, urlData, offerId: getIndividualTransactionData?.offerId, agentType, delegateSide, delegateOwnerId} ),
      );
    } else {
      message.info("No changes applied");
    }
    form.resetFields();
    setState((prevState) => ({
      ...prevState,
      editToggle: false,
      editClickedData: {},
      customModalVisible: false,
      taskId: "",
    }));
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this task?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        // Dispatch delete action or call delete function here
        dispatch(PropertyHandlers.deleteCustomTask({taskId: id, offerId: getIndividualTransactionData?.offerId, agentType, delegateSide, delegateOwnerId}));
      },
      onCancel() {},
    });
  };

  const handleSort = (sortBy, orderBy) => {
    dispatch(PropertyHandlers.getTasks({offerId: (isClientDashboard && offerId) ? offerId : getIndividualTransactionData?.offerId, agentType, sortBy, orderBy,unmounted,source, delegateSide, delegateOwnerId, token, isClientDashboard}));
  };

  useEffect(() => {
    if (state?.editToggle) {
      const updatedFormValues = {};
      if (state?.editClickedData?.eventTitle) {
        updatedFormValues.title = state?.editClickedData?.eventTitle;
      }
      if (state?.editClickedData?.dateOrDeadline) {
        updatedFormValues.dueDate = moment(
          state?.editClickedData?.dateOrDeadline,
        );
      }
      if (state?.editClickedData?.description) {
        updatedFormValues.description = state?.editClickedData?.description;
      }
      if (state?.editClickedData?.status) {
        updatedFormValues.status = state?.editClickedData?.status;
      }
      if (state?.editClickedData?.completedDateMili) {
        updatedFormValues.completedDate = moment(
          state?.editClickedData?.completedDateMili,
        );
      }
      form.setFieldsValue(updatedFormValues);
    } else {
      form.resetFields();
      form.setFieldsValue({ completedDate: moment(), status: "NOT COMPLETED" });
    }
  }, [state?.editToggle, state?.editClickedData, form]);

  const checkForDisable = checkIfDisabled({openFor: agentType, actualPropertyStatus: getIndividualTransactionData?.actualPropertyStatus, buyingStatus: getIndividualTransactionData?.buyingStatus, offerStatus: getIndividualTransactionData?.status});

  return (
    <div
      className={styles.tasksContainer}
      style={{
        border: "1px solid  rgba(0,0,0,0.2)",
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        borderRadius: "8px",
        padding: "1rem",
        marginLeft: "2px",
        display: "relative",
        marginTop: isMobile ? "8px" : "-5px",
        height: isMobile ? "calc(100vh - 9.5rem)" : "calc(100vh - 125px)",
        minWidth: "700px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "-10px 0px 15px 0px",
          height: "20px",
        }}
      >
        <div>
          <h1
            style={{
              width: "100%",
              textAlign: "center",
              fontSize: "18px",
              color: "rgb(24,144,255)",
              marginBottom: "10px",
              // marginTop: "-8px",
            }}
          >
            {address || ""}
          </h1>
        </div>
      </div>

      {getTasksDataLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              height: isMobile ? "calc(100vh - 270px)" : "calc(100vh - 240px)",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: "#fff",
              zIndex: "10",
            }}
            onScroll={(e) => {
              if (Math.round(e.target.scrollHeight - Math.round(e.target?.scrollTop)) < 900) {
                handleLoadMore();
              }
            }}
          >
            {tasksData?.length > 0 ? (
              <table
                className="licenseTable"
                onClick={(event) => {
                  event.stopPropagation();
                  setChildrenState((prevState) => ({
                    ...prevState,
                    isEdit: false,
                    editDueDate: false,
                    clickedItem: {},
                  }));
                }}
                style={{
                  width: "100%",
                  marginTop: "-5px",
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: -2,
                    left: "0px",
                    zIndex: 10,
                    // height: "50px",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        border: sortToggle.sort === "itemNo" ? "2px solid orange" : "",
                        fontSize: isMediumScreen ? 10 : isSmallScreen ? 6 : 14,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        <p>Table 3.1 Item #</p>{" "}
                        <div style={{ marginTop: "8px" }}>
                          <span>
                            <CaretUpOutlined
                              onClick={() => {
                                setChildrenState((prevState) => ({
                                  ...prevState,
                                  sortToggle: {
                                    sort: "itemNo",
                                    order: "ASC",
                                  },
                                }));
                                handleSort("itemNo", "ASC");
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: sortToggle.sort === "itemNo" && sortToggle.order === "ASC" ? "orange" : "",
                              }}
                            />
                          </span>
                          <span>
                            <CaretDownOutlined
                              onClick={() => {
                                setChildrenState((prevState) => ({
                                  ...prevState,
                                  sortToggle: {
                                    sort: "itemNo",
                                    order: "DESC",
                                  },
                                }));
                                handleSort("itemNo", "DESC");
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: sortToggle.sort === "itemNo" && sortToggle.order === "DESC" ? "orange" : "",
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </th>
                    {!isClientDashboard && <th>Status</th>}
                    <th style={{fontSize: isMediumScreen ? 10 : isSmallScreen ? 6 : 14}}>Tasks</th>
                    <th style={{fontSize: isMediumScreen ? 10 : isSmallScreen ? 6 : 14}}>Notes</th>
                    <th
                      style={{
                        border: sortToggle.sort === "dateOrDeadline" ? "2px solid orange" : "",
                        fontSize: isMediumScreen ? 10 : isSmallScreen ? 6 : 14,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "space-around",
                        }}
                      >
                        <p style={{ marginTop: "12px" }}>Due Date</p>{" "}
                        <div>
                          <div>
                            <CaretUpOutlined
                              onClick={() => {
                                setChildrenState((prevState) => ({
                                  ...prevState,
                                  sortToggle: {
                                    sort: "dateOrDeadline",
                                    order: "ASC",
                                  },
                                }));
                                handleSort("dateOrDeadline", "ASC");
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: sortToggle.sort === "dateOrDeadline" && sortToggle.order === "ASC" ? "orange" : "",
                              }}
                            />
                          </div>
                          <div>
                            <CaretDownOutlined
                              onClick={() => {
                                setChildrenState((prevState) => ({
                                  ...prevState,
                                  sortToggle: {
                                    sort: "dateOrDeadline",
                                    order: "DESC",
                                  },
                                }));
                                handleSort("dateOrDeadline", "DESC");
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: sortToggle.sort === "dateOrDeadline" && sortToggle.order === "DESC" ? "orange" : "",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                    <th
                      style={{
                        border: sortToggle.sort === "completedDateMili" ? "2px solid orange" : "",
                        fontSize: isMediumScreen ? 10 : isSmallScreen ? 6 : 14,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "space-around",
                        }}
                      >
                        <p style={{ marginTop: "12px" }}>Completed Date</p>{" "}
                        <div>
                          <div>
                            <CaretUpOutlined
                              onClick={() => {
                                setChildrenState((prevState) => ({
                                  ...prevState,
                                  sortToggle: {
                                    sort: "completedDateMili",
                                    order: "ASC",
                                  },
                                }));
                                handleSort("completedDateMili", "ASC");
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: sortToggle.sort === "completedDateMili" && sortToggle.order === "ASC" ? "orange" : "",
                              }}
                            />
                          </div>
                          <div>
                            <CaretDownOutlined
                              onClick={() => {
                                setChildrenState((prevState) => ({
                                  ...prevState,
                                  sortToggle: {
                                    sort: "completedDateMili",
                                    order: "DESC",
                                  },
                                }));
                                handleSort("completedDateMili", "DESC");
                              }}
                              style={{
                                fontSize: "18px",
                                cursor: "pointer",
                                color: sortToggle.sort === "completedDateMili" && sortToggle.order === "DESC" ? "orange" : "",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {tasksData?.slice(0, tasksCount)?.map((el, i) => (
                    <tr
                      key={el._id}
                      style={{
                        marginTop: "20px",
                        minHeight: "40px",
                        backgroundColor: el?.isManual ? "lightgoldenrodyellow" : "",
                      }}
                    >
                      <td
                        style={{
                          fontWeight: "bolder",
                          width: "9%",
                          textAlign: "center",
                        }}
                      >
                        {el.itemNo}
                      </td>
                      {!isClientDashboard && <td
                        style={{
                          textAlign: "center",
                          cursor: "pointer",
                          width: "8%",
                        }}
                      >
                        <span>
                          {el.completedDateMili ? (
                            <MdOutlineCheckBox
                              {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => (el?.isManual ? dispatch(PropertyHandlers.editCustomTask({ taskId: el._id, urlData: { status: "NOT COMPLETED" }, offerId: getIndividualTransactionData?.offerId, agentType, delegateSide, delegateOwnerId })) : handleComplete(el, "MARK_UNCOMPLETE"))})}
                              style={{
                                fontSize: "1.5rem",
                                color: "#54B435",
                              }}
                            />
                          ) : (
                            <MdOutlineCheckBoxOutlineBlank {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: () => (el?.isManual ? dispatch(PropertyHandlers.editCustomTask({ taskId: el._id, urlData: { status: "COMPLETED" }, offerId: getIndividualTransactionData?.offerId, agentType, delegateSide, delegateOwnerId })) : handleComplete(el, "MARK_COMPLETE"))})} style={{ fontSize: "1.5rem", color: "red" }} />
                          )}
                        </span>
                      </td>}
                      <td
                        style={{
                          fontWeight: "bolder",
                          width: "25%",
                        }}
                      >
                        {el.eventTitle}
                      </td>

                      {/* <td
                        style={{
                          fontWeight: "bolder",
                          width: "25%",
                        }}
                      >
                        {el.description}
                      </td> */}
                      <EditableTableCell description={el?.description} getIndividualTransactionData={getIndividualTransactionData} agentType={agentType} delegateSide={delegateSide} delegateOwnerId={delegateOwnerId} taskId={el?._id} isClientDashboard={isClientDashboard} />

                      {el?.isManual ? (
                        <td
                          style={{
                            color: "#178DFA",
                            fontWeight: "bolder",
                            textAlign: "center",
                            width: "16%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            {el?.dateOrDeadline ? moment(el?.dateOrDeadline)?.format("MMMM D, YYYY") : ""}
                          </div>
                        </td>
                      ) : (
                        <td
                          style={{
                            color: "#178DFA",
                            fontWeight: "bolder",
                            textAlign: "center",
                            // cursor: "pointer",
                            width: "16%",
                          }}
                        >
                          <>
                            <div>
                              <span style={{ color: "black", fontWeight: "normal" }}>{el?.dateOrDeadlineString?.length > 0 ? el?.dateOrDeadlineString : ""}</span>
                            </div>
                            <div
                              style={{ width: "100%" }}
                              onClick={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              {editDueDate && clickedItem?._id === el._id ? (
                                <>
                                  {" "}
                                  <DatePicker
                                    onChange={(date) =>
                                      setChildrenState((prevState) => ({
                                        ...prevState,
                                        changedDate: date && date.valueOf(),
                                      }))
                                    }
                                    defaultValue={el?.dateOrDeadline ? moment(el?.dateOrDeadline)?.format("MMMM D, YYYY") : ""}
                                    style={{ width: "60%", marginLeft: "-5%" }}
                                    format="MM-DD-YYYY"
                                  />
                                </>
                              ) : el?.dateOrDeadline ? (
                                el.type === "MEC" ? (
                                  <span>({moment(el?.dateOrDeadline)?.format("MMMM D, YYYY")})</span>
                                ) : (
                                  moment(el?.dateOrDeadline)?.format("MMMM D, YYYY")
                                )
                              ) : el.type === "TEXT" && el.startTimestamp ? (
                                // style={{color:"#262626", }}
                                <span style={{ display:"inline-block", marginRight:"0px"}}>
                                  (Start Date: <span style={{ color: "#1E90FF" }}> {moment(el?.startTimestamp)?.format("MMMM D, YYYY")}</span>)
                                </span>
                              ) : null}
                              <span
                                {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: (event) => {
                                  event.stopPropagation();
                                  handleEditDueDate(el);
                                }})}
                                style={{
                                  color: "#178DFA",
                                  fontWeight: "bolder",
                                  float: "right",
                                  cursor: "pointer",
                                }}
                              >
                                <>
                                  {editDueDate && clickedItem?._id === el._id ? (
                                    <Button
                                      type="primary"
                                      style={{
                                        marginRight: "-15%",
                                        pointerEvents: changedDate ? "auto" : "none",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDueDateChangeSave(el);
                                      }}
                                    >
                                      Save
                                    </Button>
                                  ) : (
                                    el.type === "TEXT" && el?.dateOrDeadlineString?.length > 0 && <EditTwoTone />
                                  )}
                                </>
                              </span>
                            </div>
                          </>
                        </td>
                      )}

                      {el?.isManual ? (
                        <td
                          style={{
                            color: "#178DFA",
                            fontWeight: "bolder",
                            textAlign: "center",
                            width: "28%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              textAlign: "center",
                              width: "100%",
                            }}
                          >
                            {el?.completedDateMili ? <span style={{margin:"auto"}}>{moment(el?.completedDateMili).format("MMMM D, YYYY")}</span> : <p style={{ margin: "auto" }}> N/A</p>}
                            {!isClientDashboard && <span
                              style={{
                                float: "right",
                                marginRight: "-2%",
                                marginLeft: el?.completedDateMili ? "-8px" : "-20px",
                              }}
                            >
                              <Dropdown overlay={<MenuItem item={el} />} trigger={["hover", "click"]}>
                                <MoreOutlined
                                  key="ellipsis"
                                  style={{
                                    fontSize: "26px",
                                    color: "#08c",
                                  }}
                                />
                              </Dropdown>
                            </span>}
                          </div>
                        </td>
                      ) : (
                        <td
                          onClick={(event) => {
                            if (clickedItem?._id === el?._id) event.stopPropagation();
                          }}
                          style={{
                            color: "#178DFA",
                            fontWeight: "bolder",
                            textAlign: "center",
                            width: "28%",
                          }}
                        >
                          {el?.completedDateMili ? (
                            <>
                              {isEdit && clickedItem?._id === el?._id ? (
                                <>
                                  {" "}
                                  <DatePicker
                                    onChange={(date) =>
                                      setChildrenState((prevState) => ({
                                        ...prevState,
                                        changedDate: date && date.valueOf(),
                                      }))
                                    }
                                    defaultValue={moment(el.completedDateMili)}
                                    style={{ width: "60%" }}
                                    format="MM-DD-YYYY"
                                  />
                                </>
                              ) : (
                                moment(el.completedDateMili).format("MMMM D, YYYY")
                              )}
                              <span
                                {...(checkForDisable?.disabled ? {onClick: disabledTransactionText} : {onClick: (event) => {
                                  event.stopPropagation();
                                  handleEdit(el);
                                }})}
                                style={{
                                  color: "#178DFA",
                                  fontWeight: "bolder",
                                  float: "right",
                                  cursor: "pointer",
                                }}
                              >
                                <>
                                  {isEdit && clickedItem?._id === el._id ? (
                                    <Button
                                      type="primary"
                                      style={{
                                        pointerEvents: changedDate ? "auto" : "none",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleDateChangeSave(el);
                                      }}
                                    >
                                      Save
                                    </Button>
                                  ) : (
                                    <EditTwoTone />
                                  )}
                                </>
                              </span>
                            </>
                          ) : (
                            <p> N/A</p>
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80%",
                  textAlign: "center",
                }}
              >
                    {
                      (tasksData?.length === 0 && isClientDashboard) ?
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 'large', height: '40vh' }}>
                          Tasks will be available only when there is an Accepted offer.
                        </div> :
                        <EmptyData />
                    }
              </div>
            )}
          </div>
        </>
      )}

      {/* Custom Task Modal */}
      <Modal
        style={{ height: "400px" }}
        open={state?.customModalVisible}
        title={
          state?.editToggle ? (
            <p
              style={{
                textAlign: "center",
                color: "gray",
                fontSize: "20px",
                marginBottom: "-10px",
              }}
            >
              EDIT TASK
            </p>
          ) : (
            <p
              style={{
                textAlign: "center",
                color: "gray",
                fontSize: "20px",
                alignItems: "center",
                marginBottom: "-10px",
              }}
            >
              ADD CUSTOM TASK
            </p>
          )
        }
        onCancel={handleCustomModalCancel}
        footer={false}
      >
        <Form
          {...formItemLayout}
          form={form}
          onFinish={state?.editToggle ? handleEditModal : handleCustomTask}
          scrollToFirstError
          initialValues={{
            completedDate: moment(),
          }}
        >
          <Form.Item
            className="lbl"
            label="Task"
            name="title"
            rules={[
              {
                required: state?.editToggle ? false : true,
                message: "Input task!",
              },
            ]}
          >
            <Input placeholder="Enter Task" />
          </Form.Item>

          <Form.Item
            className="lbl"
            label="Due Date"
            name="dueDate"
            rules={[
              {
                required: state?.editToggle ? false : true,
                message: "Select Due Date!",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} placeholder="Select Due Date" format="MM-DD-YYYY" allowClear />
          </Form.Item>

          <Form.Item className="lbl" label="Notes" name="description">
            <Input placeholder="Enter Notes" />
          </Form.Item>

          <Form.Item label="Status" name="status">
            <Select style={{ width: "100%" }} placeholder="Select status" onChange={handleStatusChange}>
              <Option value="COMPLETED">Completed</Option>
              <Option value="NOT COMPLETED">Not Completed</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Completed Date"
            name="completedDate"
            rules={[
              {
                required: state?.status === true ? true : false,
                message: "Select Completed Date!",
              },
            ]}
          >
            <DatePicker style={{ width: "100%" }} placeholder="Select Completed Date" format="MM-DD-YYYY" allowClear disabled={state?.status === false} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%", marginLeft: isMobile ? "" : "28%" }}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
export { Tasks };
